import React from 'react'
import { graphql } from 'gatsby'
import Meta from '../components/meta'
import Layout from '../components/layout'
import ArticlePreview from '../components/article-preview'
import Sidebar from '../components/sidebar'
import Breadcrumb from '../components/breadcrumb'

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        host
        tagline
        author
        description
      }
    }
    allContentfulBlogPost(
      sort: { fields: [publishDate], order: DESC }
      filter: {
        tags: {
          elemMatch: {slug: {eq: $slug}}
        }
      }
    ) {
      edges {
        node {
          title
          slug
          publishDate
          heroImage {
            fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid
            }
          }
          body {
            childMarkdownRemark {
              html
            }
          }
          youtubeUrl
          tags {
            name
            slug
          }
        }
      }
    }
  }
`

const BlogPostsByTag = ({ data, pageContext, location }) => {
  const site = data.site
  const posts = data.allContentfulBlogPost.edges
  const breadcrumbList = [{str: 'トップ', path: '/'}, {str: 'タグ', path: `/tags/`}, {str: pageContext.tag}]

  return (
    <Layout location={location}>
      <div style={{ background: '#fff' }}>
        <Meta
          site={site}
        />
        <div className="wrapper">
          <div className="articlesContainer">
            <div>
              <Breadcrumb breadcrumbList={breadcrumbList}/>
              <h2 className="section-headline">{pageContext.tag}</h2>
              <ul className="article-list">
                {posts.map(({ node }) => {
                  return (
                    <li key={node.slug}>
                      <ArticlePreview article={node} />
                    </li>
                  )
                })}
              </ul>
            </div>
            <Sidebar />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BlogPostsByTag